:root {
  --divider-color: rgba(0, 0, 0, 0.12);
}

.message {
	display: flex;
  width: 100%;
}

.message-title {
	position: relative;
  background-color: white;
  transition: all 1s ease;
  display: inline;
  border-radius: .4em;
  padding: .2em 0;
}

.message-title:hover {
  background-color: #F0F0F0;
}

.date-time {
	font-size: 0.875rem;
	color: var(--secondary-color);
}

.message .content {
	border-bottom: 1px solid var(--divider-color);
	width: 100%;
	margin-left: 0.8em;
	margin-bottom: 0.5em;
	padding-bottom: 1em;
}

.message .avatar {
	width: 2.5em;
	height: 2.5em;
	margin-top: 0.4em;
	border-radius: 50%;
}

.email {
  position: absolute;
	bottom: 110%;
	left: 30px;
	background: var(--tooltip-bg-color);
	padding: 5px 15px;
	color: black;
	border-radius: 10px;
	white-space: nowrap;
	opacity: 0;
	transition: all 1s ease;
}

.email:before {
	content: "";
	position: absolute;
  left: 40%;
	top: 75%;
	border-top: 20px solid var(--tooltip-bg-color);
	border-left: 20px solid transparent;
	border-right: 20px solid transparent;
}

.show {
  opacity: 1;
}
