:root {
  --secondary-color: rgba(0, 0, 0, 0.54);
  --tooltip-bg-color: #d0d0d0;
}

html {
  font-size: 100%;
  height: 100vh;
}

#root {
  height: 100vh;
}

body {
  margin: 1rem;
  padding: 0;
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.01em;
  max-width: 900px;
  height: 100vh;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0 2em;
}

header {
  display: flex;
  justify-content: center;
}

a {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.54);
  color: var(--secondary-color);
}

a:hover {
  text-decoration: underline;
}

.main {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.main .header {
  flex: 0 1 auto;
}

.main .content {
  flex: 1 1 auto;
}

.main .footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
  flex: 1 1 3em;
  padding-bottom: 1em;
}

.main .footer .backButton {
  margin:0.5em;
  padding: 0.5em 2.5em;
  border-radius: 0.5em;
  color: #ffffff;
  background-color: #808080;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  text-decoration: none;
}

@media only screen and (max-width: 600px) {
  header {
    font-size: .8em;
  }

  ul {
    margin: 0;
  }
}
